import React, { Suspense } from 'react'
import { Spinner } from '@preaction/bootstrap-clips'
import SocialBadge from './blockext-socialbadge/dist/preactioncms-blockext-socialbadge.esm.js'
import SocialBadgeSettings from './blockext-socialbadge/dist/preactioncms-blockext-socialbadge-settings.esm.js'
import MastoAlts from './blockext-mastoalts/dist/preactioncms-blockext-mastoalts.esm.js'
import MastoAltsSettings from './blockext-mastoalts/dist/preactioncms-blockext-mastoalts-settings.esm.js'
SocialBadge.Settings = SocialBadgeSettings
MastoAlts.Settings = MastoAltsSettings
const OpenPGP = React.lazy(() =>
  import('./blockext-openpgp/dist/preactioncms-blockext-openpgp.esm.js')
)
const OpenPGPSettings = React.lazy(() =>
  import(
    './blockext-openpgp/dist/preactioncms-blockext-openpgp-settings.esm.js'
  )
)
const Ytvid = React.lazy(() =>
  import('./blockext-ytvid/dist/preactioncms-blockext-ytvid.esm.js')
)
const YtvidSettings = React.lazy(() =>
  import('./blockext-ytvid/dist/preactioncms-blockext-ytvid-settings.esm.js')
)
const allowedReferrers = []
const blockExtensions = {
  MastoAlts,
  SocialBadge,
  OpenPGP: function (props) {
    return (
      <Suspense fallback={<Spinner size={3.25} />}>
        <OpenPGP {...props} />
      </Suspense>
    )
  },
  Ytvid: function (props) {
    return (
      <Suspense fallback={<Spinner size={3.25} />}>
        <Ytvid {...props} />
      </Suspense>
    )
  },
}
const blockExtensionSettings = {
  OpenPGPSettings: function (props) {
    return (
      <Suspense fallback={<Spinner size={3.25} />}>
        <OpenPGPSettings {...props} />
      </Suspense>
    )
  },
  YtvidSettings: function (props) {
    return (
      <Suspense fallback={<Spinner size={3.25} />}>
        <YtvidSettings {...props} />
      </Suspense>
    )
  },
}
blockExtensions.OpenPGP.extensionType = 'block'
blockExtensions.OpenPGP.label = 'OpenPGP'
blockExtensions.OpenPGP.Settings = blockExtensionSettings.OpenPGPSettings
blockExtensions.Ytvid.extensionType = 'block'
blockExtensions.Ytvid.label = 'Youtube Video'
blockExtensions.Ytvid.Settings = blockExtensionSettings.YtvidSettings
const menuExtensions = {}
export { allowedReferrers, blockExtensions, menuExtensions }
